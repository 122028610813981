import { faCalendarDays, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountingWorkflow } from '../../amplitude';
import BookFollowUpModalContent from '../../Appointments/BookFollowUp/BookFollowUpModalContent';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import Button from '../../shared/Button/Button';
import { toShortDateTimeString } from '../../shared/dates/dates';
import Modal from '../../shared/Modal/Modal';
import PayoutInvoiceItemsTable from './PayoutInvoiceItemsTable';
import { usePrintedPayoutInvoice } from './usePrintedPayoutInvoice';

type Props = {
  appointment: Appointment;
  authProvider: AuthProvider;
};
const PayoutInvoiceDetails: React.FC<Props> = ({ appointment, authProvider }) => {
  const navigate = useNavigate();
  const [isBookFollowUpModalOpen, setIsBookFollowUpModalOpen] = useState(false);
  const buttonStyling = 'bg-primary text-white hover:opacity-75';
  const { printButton, printComponent } = usePrintedPayoutInvoice({
    appointment,
    authProvider,
  });

  const renderActionButtons = (appointment: Appointment) => {
    return (
      <div className={'flex flex-row gap-2 h-[90%]'}>
        <Button
          onClick={() => navigate(`/patients/${appointment.userId}/treatment-plans/new`)}
          id={'Add-treatment-plan-button'}
          className={buttonStyling}
          trackingLabel="Add Treatment Plan Button"
          workflow={AccountingWorkflow}
          context="payoutInvoiceDetails"
        >
          <div className="flex items-center gap-1 px-1">
            <FontAwesomeIcon
              icon={faFileMedical}
              className="text-icon mr-2"
              style={{ color: 'white', transform: 'scale(1.5)' }}
            />
            <span>Add Treatment Plan</span>
          </div>
        </Button>
        <Button
          id={'book-follow-up-button'}
          onClick={() => setIsBookFollowUpModalOpen(true)}
          className={buttonStyling}
          trackingLabel="Book Follow-Up Button"
          workflow={AccountingWorkflow}
          context="payoutInvoiceDetails"
        >
          <div className="flex items-center gap-1 px-1">
            <FontAwesomeIcon
              icon={faCalendarDays}
              className="text-icon mr-2"
              style={{ color: 'white', transform: 'scale(1.5)' }}
            />
            <span>Book Follow-up</span>
          </div>
        </Button>
        {printButton}
      </div>
    );
  };

  return (
    <div id={'invoice-page'} className={'flex flex-col gap-3 text-base-content'}>
      <div id={'header'} className={'flex flex-row justify-between'}>
        <span id={'title'} className={'font-semibold text-2xl'}>
          Invoice Details
        </span>
        <button
          className={'text-base-content font-semibold hover:opacity-75 text-sm'}
          onClick={() => navigate('/accounting/payouts')}
        >
          <span>&larr; </span>
          Return to Payouts
        </button>
      </div>
      <div id={'body'} className={'bg-white rounded-md flex flex-col gap-2'}>
        <div id={'info-and-controls'} className={'flex flex-row m-4 justify-between'}>
          <div id={'invoice-info'} className={'flex flex-col gap-1.5 text-left'}>
            <span className={'font-bold'}>
              {appointment.user?.firstName} {appointment.user?.lastName}
            </span>
            <span className={'text-sm'}>
              {toShortDateTimeString(appointment.start, appointment.timeZone)}
            </span>
          </div>
          {renderActionButtons(appointment)}
        </div>
        <div id={'items-list'}>
          <PayoutInvoiceItemsTable authProvider={authProvider} appointment={appointment} />
        </div>
      </div>
      <Modal isOpen={isBookFollowUpModalOpen} onRequestClose={() => {}} shape={'big-square'}>
        <BookFollowUpModalContent
          authProvider={authProvider}
          patientId={appointment.userId}
          includeActionButtons={true}
          onCloseClick={() => setIsBookFollowUpModalOpen(false)}
          appointment={appointment}
        />
      </Modal>
      {printComponent}
    </div>
  );
};

export default PayoutInvoiceDetails;
