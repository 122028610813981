import React, { Fragment } from 'react';

import { Invoice, InvoiceItem, PricingSystemItem } from '../../ServiceContext/invoices';
import { isNumber } from '../../shared/math/math';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

const calculateFlossyFeeForNewPricingCase = (pricingSystemItem: PricingSystemItem) =>
  pricingSystemItem.patientPrice - pricingSystemItem.dentistPayout;

const calculateAdjustedFlossyFee = (pricingSystemItem: PricingSystemItem) => {
  const adjustedDentistPayout = isNumber(pricingSystemItem.adjustedDentistPayout)
    ? pricingSystemItem.adjustedDentistPayout
    : 0;
  const adjustedPatientPrice = isNumber(pricingSystemItem.adjustedPatientPrice)
    ? pricingSystemItem.adjustedPatientPrice
    : 0;
  return adjustedPatientPrice - adjustedDentistPayout;
};

const PriceCell: React.FC<{
  value: number | undefined;
  isAdjusted: boolean;
  className?: string;
}> = ({ value, isAdjusted, className = '' }) =>
  value ? (
    <span className={`${isAdjusted ? 'text-decoration-line-through' : ''} ${className}`}>
      {stringifyMoney(value, { includeCommas: true })}
    </span>
  ) : (
    <span>---</span>
  );

const AdjustedPriceCell: React.FC<{ value: number | null | undefined }> = ({ value }) =>
  value ? (
    <span className="text-primary font-bold">{stringifyMoney(value, { includeCommas: true })}</span>
  ) : null;

type NewPricingDataProps = {
  procedureInvoiceItems: InvoiceItem[];
  invoice: Invoice;
  alternateRowStyle?: boolean;
  patientHasInsurance: boolean;
  isFlossyAdmin: boolean;
};

const NewPricingData: React.FC<NewPricingDataProps> = ({
  procedureInvoiceItems,
  invoice,
  alternateRowStyle,
  patientHasInsurance,
  isFlossyAdmin,
}) => {
  return (
    <Fragment>
      {procedureInvoiceItems.map((item, index) => {
        const pricingItem = invoice.pricingQuote?.items.find(
          (pricing) => pricing.code === item.code
        );
        const isCDTAdjusted = Boolean(pricingItem && pricingItem.adjustedPatientPrice);

        return (
          <tr
            key={index}
            className={`text-sm ${
              alternateRowStyle
                ? index % 2 === 0
                  ? 'bg-white'
                  : 'bg-blue-50 bg-opacity-75'
                : 'border-t border-rule'
            }`}
          >
            <td className="text-left py-3 pl-6 font-semibold">{item.code}</td>
            <td className="text-left py-3 pl-6">
              {item.text}
              {isCDTAdjusted && <span className="text-primary font-medium"> (Adjusted)</span>}
            </td>
            <td className="text-right py-3 pr-6 text-base-content">
              {patientHasInsurance ? (
                '---'
              ) : (
                <PriceCell
                  value={pricingItem?.anchorPrice}
                  isAdjusted={false}
                  className="text-base-content-inactive line-through"
                />
              )}
            </td>
            <td className="text-right py-3 pr-6">
              {patientHasInsurance ? (
                '---'
              ) : (
                <div className="flex flex-col gap-1">
                  <PriceCell value={pricingItem?.patientPrice} isAdjusted={isCDTAdjusted} />
                  <AdjustedPriceCell value={pricingItem?.adjustedPatientPrice} />
                </div>
              )}
            </td>
            {isFlossyAdmin && (
              <td className="text-right py-3 pr-6">
                {patientHasInsurance ? (
                  '---'
                ) : (
                  <div className="flex flex-col gap-1">
                    {pricingItem && (
                      <PriceCell
                        value={calculateFlossyFeeForNewPricingCase(pricingItem)}
                        isAdjusted={isCDTAdjusted}
                      />
                    )}
                    {pricingItem && (
                      <AdjustedPriceCell value={calculateAdjustedFlossyFee(pricingItem)} />
                    )}
                  </div>
                )}
              </td>
            )}
            <td className="text-right py-3 pr-6">
              {patientHasInsurance ? (
                '---'
              ) : (
                <div className="flex flex-col gap-1">
                  <PriceCell value={item.dentistPayout} isAdjusted={isCDTAdjusted} />
                  <AdjustedPriceCell value={pricingItem?.adjustedDentistPayout} />
                </div>
              )}
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export default NewPricingData;
