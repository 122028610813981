import { useRef } from 'react';

import ClearButton from '../../../shared/ClearButton';

interface SearchInputProps {
  search: string;
  setSearch: (search: string) => void;
  setHighlightedIndex: (index: number) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ search, setSearch, setHighlightedIndex }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="flex items-center mx-3 my-1 relative">
      <input
        autoFocus
        ref={inputRef}
        type="text"
        className="py-2 pl-2 pr-8 grow border-rule rounded-md border focus:outline-base-content-navigation"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder="Search for a practice or a dentist..."
      />
      {search && (
        <div className="absolute top-[1px] right-[2px]">
          <ClearButton
            onClick={() => {
              setSearch('');
              if (inputRef.current !== null) {
                inputRef.current.focus();
              }
              setHighlightedIndex(0);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
