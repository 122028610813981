import { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { AccountingWorkflow } from '../../amplitude';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import Button from '../../shared/Button/Button';
import { PrintedPayoutInvoice } from './PrintedPayoutInvoice';

export const usePrintedPayoutInvoice = ({
  appointment,
  authProvider,
}: {
  appointment: Appointment;
  authProvider: AuthProvider;
}) => {
  const invoicePrintedContentRef = useRef<HTMLDivElement>(null);

  return {
    printButton: (
      <ReactToPrint
        trigger={() => {
          return (
            <Button
              onClick={() => {}}
              trackingLabel="Print Invoice Button"
              workflow={AccountingWorkflow}
              context="payoutInvoiceDetails"
              color={'tertiary'}
            >
              Print Invoice
            </Button>
          );
        }}
        content={() => invoicePrintedContentRef.current}
      />
    ),
    printComponent: (
      <div className={'content-to-print hidden'}>
        <PrintedPayoutInvoice
          appointment={appointment}
          ref={invoicePrintedContentRef}
          authProvider={authProvider}
        />
      </div>
    ),
  };
};
