import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { BookingLedger, getBookingLedger, LedgerMonth } from '../../../../API/bookingsAndCredits';
import { ErrorResponse } from '../../../../API/response';
import { AuthProvider } from '../../../../Authentication/Authentication';
import Alert, { errorAlert } from '../../../../shared/Alert';
import { useURLQuery } from '../../../../shared/routing/routing';
import ShareInvoiceForPeriodView from './ShareInvoiceForPeriodView';

type Props = {
  authProvider: AuthProvider;
};

const ShareInvoice: React.FC<Props> = ({ authProvider }) => {
  const { practiceId } = useParams<{ practiceId: string }>();
  const practice = authProvider.managedDentists?.find(
    (dentist) => dentist.practice?.id === practiceId
  )?.practice;

  const { query } = useURLQuery();
  const selectedPeriod = query.get('period');
  const getBookingLedgerKey = useMemo(() => ['getBookingLedger', practiceId], [practiceId]);
  const {
    isLoading: isLoadingLedger,
    error: fetchBookingLedgerError,
    data: bookingLedger,
  } = useQuery<BookingLedger | null, ErrorResponse>(
    getBookingLedgerKey,
    () => {
      return getBookingLedger({
        authProvider,
        practiceId: practiceId ?? '',
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  if (fetchBookingLedgerError) {
    return <Alert {...errorAlert(fetchBookingLedgerError.errorResponse)} />;
  }

  let periodLedger: LedgerMonth | null = null;
  if (bookingLedger && selectedPeriod && !isLoadingLedger) {
    periodLedger = bookingLedger.monthlyBreakdown[selectedPeriod];
    return (
      <ShareInvoiceForPeriodView
        authProvider={authProvider}
        practice={practice}
        periodLedger={periodLedger}
        selectedPeriod={selectedPeriod}
      />
    );
  }

  return <Alert {...errorAlert('No period selected')} />;
};

export default ShareInvoice;
