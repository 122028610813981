import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import {
  getColorFromStatus,
  getStatusFromAppointment,
} from '../../../Appointments/AppointmentUtils';
import { Appointment } from '../../../ServiceContext/appointments';
import PortalContainer from '../../../shared/Container/PortalContainer';
import { toShortDateString, toShortTimeString } from '../../../shared/dates/dates';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import LinkText from '../../../shared/Text/LinkText';

type Props = {
  patientAppointmentsData: Appointment[];
};

const PatientAppointmentsTable: React.FC<Props> = ({ patientAppointmentsData }) => {
  const navigate = useNavigate();

  let pastAppointments: Appointment[] = [];

  if (patientAppointmentsData) {
    pastAppointments = patientAppointmentsData.filter(
      (appointment) => appointment.start < DateTime.local().toUTC().toString()
    );
  }

  const generateColumnDef = (): ColumnDef<Appointment>[] => {
    return [
      {
        columnKey: 'status',
        title: 'Status',
        render: (record: Appointment) => {
          return (
            <div className="ml-4 ">
              <div
                className={`py-1 px-1.5 rounded-2xl w-fit text-primary-content font-semibold text-xs ${getColorFromStatus(
                  record
                )}`}
              >
                {getStatusFromAppointment(record)}
              </div>
            </div>
          );
        },
        widthProportion: 1,
      },
      {
        columnKey: 'date',
        title: 'Date',
        render: (record) => {
          return (
            <div className="appointment-start flex flex-col">
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  navigate(`/appointments/${record.id}`);
                }}
              >
                {toShortDateString(record.start, record.timeZone)}
              </span>
              <span className="appointment-start-time text-xs mt-1">
                {toShortTimeString(record.start, record.timeZone)}
              </span>
            </div>
          );
        },
        widthProportion: 1,
      },
      {
        columnKey: 'bookedAt',
        title: 'Booked At',
        render: (record) => {
          return (
            <div className="appointment-created-date flex flex-col">
              <span>{toShortDateString(record.createdAt, record.timeZone)}</span>
              <span className="appointment-created-time text-xs italic mt-1">
                {toShortTimeString(record.createdAt, record.timeZone)}
              </span>
            </div>
          );
        },
        widthProportion: 1,
      },
      {
        columnKey: 'services',
        title: 'Services',
        render: (record: Appointment) => {
          if (record.services) {
            return record.services.map((service) => service.name).join(', ');
          }

          return '---';
        },
        widthProportion: 1,
      },
      {
        columnKey: 'dentist',
        title: 'Dentist',
        render: (record) => {
          if (!record.dentist) {
            return 'N/A';
          }
          return <>{`${record.dentist.firstName} ${record.dentist.lastName}`}</>;
        },
        widthProportion: 1,
      },
      {
        columnKey: 'invoiceTotal',
        title: 'Invoice Total',
        render: (record) => {
          if (!record.invoice) {
            return 'N/A';
          }
          return (
            <LinkText
              onClick={() => {
                navigate(`/appointments/${record.id}/invoicing`);
              }}
              className=" !text-sm"
            >
              {record.invoice?.total &&
                stringifyMoney(record.invoice?.total, { includeCommas: true })}
            </LinkText>
          );
        },
        widthProportion: 1,
      },
    ];
  };

  return (
    <PortalContainer id="patient-past-appointments-table-container" padding="p-0">
      <Table
        id="patient-past-appointments-table"
        columns={generateColumnDef()}
        data={pastAppointments}
        rowKeyGenerator={(record: Appointment) => `${record.id}`}
        rowClassName="patient-past-appointment-row"
      />
    </PortalContainer>
  );
};

export default PatientAppointmentsTable;
