import { useEffect, useMemo, useState } from 'react';

import { ClickTrackingAction, GeneralWorkflow, runFTrack } from '../../../../amplitude';
import { FilteredDentistsByPractice } from '../../../../HomePage/components/DentistSearch/types';
import { Practice } from '../../../../ServiceContext/user';
import { usernameFromDentist } from '../../../../shared/strings';

export const usePracticeSearch = (
  practices: Practice[],
  practiceDentistMap: FilteredDentistsByPractice,
  onChange: (option: Practice | null) => void,
  selectedPracticeId: string | null,
  setSelectedPracticeId: (id: string | null) => void
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedSearch(search), 200);
    return () => clearTimeout(timer);
  }, [search]);

  const filteredPractices = useMemo(() => {
    if (debouncedSearch.length < 3) {
      return practices;
    }

    return practices.filter((practice) => {
      const practiceMatch = practice.name.toLowerCase().includes(debouncedSearch.toLowerCase());
      const dentistMatch = (practiceDentistMap[practice.id] || []).some((dentist) =>
        usernameFromDentist(dentist).toLowerCase().includes(debouncedSearch.toLowerCase())
      );
      return practiceMatch || dentistMatch;
    });
  }, [debouncedSearch, practices, practiceDentistMap]);

  const handleOptionClick = (practice: Practice | null) => {
    setIsOpen(false);
    if (onChange) {
      onChange(practice);
    }
    runFTrack({
      event: 'Select Practice',
      workflow: GeneralWorkflow,
      action: ClickTrackingAction,
      context: 'practiceSearchSelect',
      componentId: 'practiceSearchSelectDropdown',
      extraProps: {
        practiceId: practice?.id,
        practiceName: practice?.name,
      },
    });
    setSelectedPracticeId(practice?.id ?? null);
  };

  const getSelectedPractice = (): Practice | undefined => {
    return practices.find((practice) => practice.id === selectedPracticeId);
  };

  return {
    isOpen,
    setIsOpen,
    filteredPractices,
    handleOptionClick,
    getSelectedPractice,
    debouncedSearch,
    search,
    setSearch,
  };
};
