import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { BookingsAndCreditsWorkflow, ClickTrackingAction, runFTrack } from '../../../amplitude';
import {
  BookingLedger,
  getBookingLedger,
  getPaymentHistory,
  PaymentHistoryRecord,
} from '../../../API/bookingsAndCredits';
import { getPractices } from '../../../API/practice';
import { ErrorResponse } from '../../../API/response';
import { AuthProvider } from '../../../Authentication/Authentication';
import { Practice } from '../../../ServiceContext/user';
import Alert, { errorAlert } from '../../../shared/Alert';
import { checkUserRole } from '../../../shared/auth';
import Cookies from '../../../shared/cookies/cookies';
import { useURLQuery } from '../../../shared/routing/routing';
import Tabs from '../../../shared/Tabs/Tabs';
import { utcTimestampToBookingPeriod } from '../BookingPeriod';
import CurrentMonth from './CurrentMonth';
import PastMonths from './PastMonths';
import { MonthlyLedger } from './PastMonthsLedgerTable';
import PaymentHistory from './PaymentHistory';
import PracticeSearchSelect from './PracticeSearch/PracticeSearchSelect';
import ReferralCredits from './ReferralCreditsTab/ReferralCredits';
import useTestOnlyClearBookingsAndCreditsPaymentHistory from './TestOnlyClearBookingsAndCreditsPaymentHistory';

type Props = {
  authProvider: AuthProvider;
};

type TabKey = 'current-month' | 'previous-months' | 'payment-history' | 'referral-credits';

const NewBookingsAndCreditsExperience: React.FC<Props> = ({ authProvider }) => {
  const { query, updateQuery } = useURLQuery();
  let activeTabKey: string;
  let tabFromQuery = query.get('tab');

  const practiceId = query.get('practiceId');

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const getPracticesKey = ['getPractices', practiceId];
  const { data: resPractices } = useQuery<Practice[], ErrorResponse>(
    getPracticesKey,
    () => {
      return getPractices({ authProvider });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const practices = useMemo(() => resPractices || [], [resPractices]);

  // This useEffect is responsible for automatically setting the "selected" practice to this
  // manager user's only practice in the case that they are a regular office manager who only
  // is responsible for one practice.
  useEffect(() => {
    const { isFlossyAdmin, isOfficeManager, onlyResponsibleForOnePractice } =
      checkUserRole(authProvider);
    if (!isFlossyAdmin && isOfficeManager && onlyResponsibleForOnePractice) {
      onNewPracticeSelected(practices[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practices]);

  if (tabFromQuery) {
    activeTabKey = tabFromQuery;
  } else {
    if (localStorage.getItem('bookingsAndCreditsTab') === 'current-month') {
      activeTabKey = 'current-month' as TabKey;
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'previous-months') {
      activeTabKey = 'previous-months';
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'payment-history') {
      activeTabKey = 'payment-history';
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'referral-credits') {
      activeTabKey = 'referral-credits';
    } else {
      activeTabKey = 'current-month';
    }
  }

  const queryClient = useQueryClient();

  const onNewPracticeSelected = useCallback(
    (selected: Practice | null) => {
      if (selected) {
        setCookies({ page: '1', practiceId: selected.id });
      } else {
        setCookies({ page: '1', practiceId: null });
      }
    },
    [setCookies]
  );

  const { component: TestOnlyClearBookingsAndCreditsPaymentHistoryComponent } =
    useTestOnlyClearBookingsAndCreditsPaymentHistory({
      authProvider,
      practiceId: practiceId || '',
    });

  const getBookingLedgerKey = useMemo(() => ['getBookingLedger', practiceId], [practiceId]);

  const { error: fetchBookingLedgerError, data: bookingLedger } = useQuery<
    BookingLedger | null,
    ErrorResponse
  >(
    getBookingLedgerKey,
    () => {
      return getBookingLedger({
        authProvider,
        practiceId: practiceId || null,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const getPaymentHistoryKey = useMemo(() => ['getPaymentHistory', practiceId], [practiceId]);
  const { isFlossyAdmin, isOfficeManager, haveMultiplePractices } = checkUserRole(authProvider);
  const enablePaymentHistoryFetching = () => {
    if (haveMultiplePractices) {
      return Boolean(practiceId);
    }
    return isFlossyAdmin || isOfficeManager;
  };

  const {
    isLoading: isLoadingPaymentHistory,
    error: fetchPaymentHistoryError,
    data: paymentHistoryRecords,
  } = useQuery<PaymentHistoryRecord[], ErrorResponse>(
    getPaymentHistoryKey,
    () => {
      return getPaymentHistory({
        authProvider,
        practiceId: practiceId || null,
      });
    },
    {
      enabled: enablePaymentHistoryFetching(),
      refetchOnWindowFocus: false,
    }
  );

  const currentDate = new Date();
  const currentBookingPeriodAPIFriendly = utcTimestampToBookingPeriod(
    currentDate.toISOString()
  ).apiFriendlyForm;
  const currentLedgerMonth = bookingLedger?.monthlyBreakdown[currentBookingPeriodAPIFriendly];

  const { monthlyBreakdown } = bookingLedger || { monthlyBreakdown: {} };

  const allLedgerMonths = Object.values(monthlyBreakdown);
  const allCreditItems = allLedgerMonths.flatMap((ledgerMonth) => ledgerMonth.credits.items);
  const allChargeItems = allLedgerMonths.flatMap((ledgerMonth) => ledgerMonth.charges.items);

  const monthlyBreakdownEntries = Object.entries(monthlyBreakdown);
  const pastLedgerMonthEntries = monthlyBreakdownEntries.filter(
    ([monthYear]) => monthYear !== currentBookingPeriodAPIFriendly
  );
  const pastMonthlyLedgerData: MonthlyLedger[] = pastLedgerMonthEntries
    .map(([monthYear, ledgerMonth]) => ({ monthYear, ledgerMonth }))
    .sort((a, b) => {
      const aDate: Date = new Date(a.monthYear);
      const bDate: Date = new Date(b.monthYear);
      return bDate.getTime() > aDate.getTime() ? 1 : -1;
    });

  if (fetchBookingLedgerError) {
    return <Alert {...errorAlert(fetchBookingLedgerError.errorResponse)} />;
  }

  const onTabChanged = (newKey: string) => {
    runFTrack({
      event: 'Select Bookings and Credit Tab',
      workflow: BookingsAndCreditsWorkflow,
      action: ClickTrackingAction,
      context: 'bookingsAndCredits',
      componentId: 'bookingsAndCreditsTabs',
      extraProps: {
        tab: newKey,
      },
    });
    localStorage.setItem('bookingsAndCreditsTab', newKey);

    // This is here to allow the user to clear their past month choice when zeroed in on a specific one.
    updateQuery({
      period: null,
      page: '1',
      tab: newKey,
    });
  };

  const renderTabs = () => {
    return (
      <Tabs activeTabKey={activeTabKey} onTabChanged={onTabChanged}>
        <Tabs.Tab tabKey="current-month" title="Current Month">
          {currentLedgerMonth && (
            <CurrentMonth
              authProvider={authProvider}
              ledgerMonth={currentLedgerMonth}
              allCreditItems={allCreditItems}
              practiceId={practiceId || ''}
              queryContext={{
                client: queryClient,
                key: getBookingLedgerKey,
              }}
              monthYearString={currentBookingPeriodAPIFriendly}
            />
          )}
        </Tabs.Tab>
        <Tabs.Tab tabKey="previous-months" title="Previous Months">
          <PastMonths
            authProvider={authProvider}
            practiceId={practiceId || ''}
            monthlyLedgerData={pastMonthlyLedgerData}
            queryContext={{
              client: queryClient,
              key: getBookingLedgerKey,
            }}
            paymentHistoryRecords={paymentHistoryRecords || []}
            allCreditItems={allCreditItems}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="payment-history" title="Payment History">
          <PaymentHistory
            paymentHistoryRecords={paymentHistoryRecords}
            fetchPaymentHistoryError={fetchPaymentHistoryError}
            isLoadingPaymentHistory={isLoadingPaymentHistory}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="referral-credits" title={'Referral Credits'}>
          <ReferralCredits allCreditItems={allCreditItems} allChargeItems={allChargeItems} />
        </Tabs.Tab>
      </Tabs>
    );
  };

  return (
    <div id={'bookings-and-credits-page flex flex-col gap-3'}>
      <div className="flex flex-row justify-between">
        <span className="header text-2xl font-semibold text-left">{`Bookings and Credits`}</span>
        {(isFlossyAdmin || haveMultiplePractices) && (
          <PracticeSearchSelect
            authProvider={authProvider}
            practices={practices}
            onChange={onNewPracticeSelected}
            showSearchField={true}
            disableViewAll={false}
            defaultSelectedPracticeId={practiceId}
          />
        )}
      </div>
      {Boolean(TestOnlyClearBookingsAndCreditsPaymentHistoryComponent) && (
        <div className="w-[200px]">{TestOnlyClearBookingsAndCreditsPaymentHistoryComponent}</div>
      )}
      {renderTabs()}
    </div>
  );
};

export default NewBookingsAndCreditsExperience;
