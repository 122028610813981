import React from 'react';

import { FilteredDentistsByPractice } from '../../../../HomePage/components/DentistSearch/types';
import { CrowdIcon } from '../../../../HomePage/components/Icons';
import { Practice } from '../../../../ServiceContext/user';
import { usernameFromDentist } from '../../../../shared/strings';

interface PracticeListProps {
  practices: Practice[];
  dentistsByPractice: FilteredDentistsByPractice;
  highlightedIndex: number;
  handleOptionClick: (practice: Practice) => void;
}

const PracticeList: React.FC<PracticeListProps> = ({
  practices,
  dentistsByPractice,
  highlightedIndex,
  handleOptionClick,
}) => {
  console.log('practices', practices);
  return (
    <div className="items-start justify-start flex flex-col">
      {practices.map((practice, index) => (
        <div key={practice.id}>
          <button
            className={`block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200 ${
              index === highlightedIndex ? 'bg-base-100' : ''
            }`}
            onClick={() => handleOptionClick(practice)}
          >
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-4 place-content-center">
                <CrowdIcon color="black" />
              </div>
              <div className="text-start font-semibold">{practice.name}</div>
            </div>
            <div className="text-xs text-gray-500 ml-6">
              {(dentistsByPractice[practice.id] || []).map(usernameFromDentist).join(', ')}
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

export default PracticeList;
