import { AuthProvider } from '../Authentication/Authentication';

interface UserRoleInfo {
  isFlossyAdmin: boolean;
  isOfficeManager: boolean;
  onlyResponsibleForOnePractice: boolean;
  haveMultiplePractices: boolean;
}

export function checkUserRole(authProvider: AuthProvider): UserRoleInfo {
  const isFlossyAdmin = authProvider.isFlossyAdmin;
  const isOfficeManager = authProvider.authUser?.user.managerType === 'office_manager';
  const onlyResponsibleForOnePractice = authProvider.authUser?.user.practices?.length === 1;
  let haveMultiplePractices: boolean = false;
  if (authProvider.authUser) {
    haveMultiplePractices = authProvider.authUser?.user.practices?.length > 1;
  }

  return {
    isFlossyAdmin,
    isOfficeManager,
    onlyResponsibleForOnePractice,
    haveMultiplePractices,
  };
}
