import { extractInvoicingComponents } from '../../Appointments/Invoicing/utilities';
import { Invoice } from '../../ServiceContext/invoices';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type Props = {
  invoice: Invoice;
  patientHasInsurance: boolean;
  isFlossyAdmin: boolean;
};
const NewPricingTotalsRow: React.FC<Props> = ({ invoice, patientHasInsurance, isFlossyAdmin }) => {
  const { flossyFee, dentistPayoutTotal } = extractInvoicingComponents({ invoice });
  const anchorPricingTotal =
    invoice.pricingQuote?.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.anchorPrice,
      0
    ) || 0;

  if (patientHasInsurance) {
    return (
      <tr id={'totals-row'} className="text-sm font-bold text-right py-3 pr-6">
        <td id={'label'} className="text-left py-3 pl-6 font-semibold">
          Totals
        </td>
        <td id={'empty'} className="text-left py-3 pl-6"></td>
        <td id={'anchor-price-total'} className="text-right py-3 pr-6 text-base-content-inactive">
          ---
        </td>
        <td id={'patient-price-total'} className="text-right py-3 pr-6">
          ---
        </td>
        {isFlossyAdmin && (
          <td id={'flossy-fee-total'} className="text-right py-3 pr-6">
            ---
          </td>
        )}
        <td id={'dentist-payout-total'} className="text-right py-3 pr-6">
          ---
        </td>
      </tr>
    );
  }

  return (
    <tr id={'totals-row'} className="text-sm font-bold text-right py-3 pr-6">
      <td id={'label'} className="text-left py-3 pl-6 font-semibold">
        Totals
      </td>
      <td id={'empty'} className="text-left py-3 pl-6"></td>
      <td id={'anchor-price-total'} className="text-right py-3 pr-6 text-base-content-inactive">
        {stringifyMoney(anchorPricingTotal, { includeCommas: true })}
      </td>
      <td id={'patient-price-total'} className="text-right py-3 pr-6">
        {stringifyMoney(invoice.total, { includeCommas: true })}
      </td>
      {isFlossyAdmin && (
        <td id={'flossy-fee-total'} className="text-right py-3 pr-6">
          {stringifyMoney(flossyFee, { includeCommas: true })}
        </td>
      )}
      <td id={'dentist-payout-total'} className="text-right py-3 pr-6">
        {stringifyMoney(dentistPayoutTotal, { includeCommas: true })}
      </td>
    </tr>
  );
};

export default NewPricingTotalsRow;
