import { Dentist, Practice } from '../../../ServiceContext/user';
import { usernameFromDentist } from '../../../shared/strings';
import { FilteredDentistsByPractice } from './types';

export const groupDentistsByPractice = (dentists: Dentist[]): FilteredDentistsByPractice => {
  return dentists.reduce((map, dentist) => {
    if (dentist.practice === null) {
      return map;
    }
    if (!map[dentist.practice.id]) {
      map[dentist.practice.id] = [];
    }
    map[dentist.practice.id].push(dentist);
    return map;
  }, {} as FilteredDentistsByPractice);
};

export const extractPractices = (dentists: Dentist[]): Practice[] => {
  const extractPractice = (dentist: Dentist) => dentist.practice;
  const isNotNull = <T>(value: T | null | undefined): value is T => value != null;
  const uniqueById = <T extends { id: string }>(value: T, index: number, self: T[]) =>
    index === self.findIndex((item) => item.id === value.id);
  const compareByName = (a: Practice, b: Practice) => (a.name ?? '').localeCompare(b.name ?? '');

  return dentists.map(extractPractice).filter(isNotNull).filter(uniqueById).sort(compareByName);
};

export const filterDentists = (
  dentistsByPractice: FilteredDentistsByPractice,
  search: string
): FilteredDentistsByPractice => {
  const filtered = { ...dentistsByPractice };

  for (const practiceId in filtered) {
    filtered[practiceId] = filtered[practiceId].filter((dentist) =>
      usernameFromDentist(dentist).toLowerCase().includes(search.toLowerCase())
    );

    if (filtered[practiceId].length === 0) {
      delete filtered[practiceId];
    }
  }

  return filtered;
};
