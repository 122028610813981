import { Fragment } from 'react';

import { extractInvoicingComponents } from '../../Appointments/Invoicing/utilities';
import { Invoice } from '../../ServiceContext/invoices';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type FlossyFeeSummaryRowsProps = {
  usesNewPricingSystem: boolean;
  invoice: Invoice;
  patientHasInsurance: boolean;
  isFlossyAdmin: boolean;
};

const FlossyFeeSummaryRows: React.FC<FlossyFeeSummaryRowsProps> = ({
  usesNewPricingSystem,
  invoice,
  patientHasInsurance,
  isFlossyAdmin,
}) => {
  const renderEmptyCells = (count: number) => (
    <>
      {Array.from({ length: count }, (_, index) => (
        <td key={index}></td>
      ))}
    </>
  );

  const getEmptyCellCount = () => {
    if (usesNewPricingSystem && !isFlossyAdmin) return 3;
    if (!usesNewPricingSystem && !isFlossyAdmin) return 2;
    if (usesNewPricingSystem && isFlossyAdmin) return 4;
    if (!usesNewPricingSystem && isFlossyAdmin) return 3;
    return 0; // This should never happen, but TypeScript will be happy
  };

  const { flossyFee } = extractInvoicingComponents({ invoice });

  if (patientHasInsurance) {
    return (
      <Fragment>
        <tr className={'border-t-2 border-t-rule'}></tr>
        <tr>
          <td className={'text-sm font-semibold italic text-left py-3 pl-6'}>
            Patient paid with insurance
          </td>
        </tr>
        <tr id={'total-provider-payout-row'}>
          <td className={'text-lg font-bold text-left py-3 pl-6'}>Total Provider Payout</td>
          {renderEmptyCells(getEmptyCellCount())}
          <td className={'text-lg font-bold text-right pr-6'}>
            {stringifyMoney(0, { includeCommas: true })}
          </td>
        </tr>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <tr id={'treatment-total-row'} className={'border-t-2 border-t-rule'}>
        <td className={'text-sm font-semibold text-left py-3 pl-6'}>Treatment Total</td>
        {renderEmptyCells(getEmptyCellCount())}
        <td className={'text-sm font-semibold text-right pr-6'}>
          {stringifyMoney(invoice.total || 0, { includeCommas: true })}
        </td>
      </tr>
      {isFlossyAdmin && (
        <tr id={'flossy-fee-total-row'}>
          <td className={'text-sm font-semibold text-left py-3 pl-6'}>Flossy Fee</td>
          {renderEmptyCells(getEmptyCellCount())}
          <td className={'text-sm font-semibold text-right opacity-50 pr-6'}>
            -{stringifyMoney(flossyFee, { includeCommas: true })}
          </td>
        </tr>
      )}
      <tr id={'total-provider-payout-row'}>
        <td className={'text-lg font-bold text-left py-3 pl-6'}>Total Provider Payout</td>
        {renderEmptyCells(getEmptyCellCount())}
        <td className={'text-lg font-bold text-right pr-6'}>
          {stringifyMoney(invoice.payoutAmount || 0, { includeCommas: true })}
        </td>
      </tr>
    </Fragment>
  );
};

export default FlossyFeeSummaryRows;
