import { useEffect, useRef, useState } from 'react';

import { useSelectedDentist } from '../../../Dentists/SelectedDentistContext';
import { Dentist } from '../../../ServiceContext/user';
import DentistList from './DentistList';
import SearchInput from './SearchInput';
import SelectButton from './SelectButton';
import { useDentistSearch } from './useDentistSearch';

/**
 * A searchable dropdown select component for selecting a dentist.
 * @param options - The list of dentists to select from.
 * @param onChange - The function to call when a dentist is selected.
 *                   This component will handle updating the selected dentist in the context.
 * @param showSearchField - Whether to show the search field or not.
 * @param disableViewAll
 */
const DentistSearchSelect = ({
  dentists,
  onChange,
  showSearchField,
  disableViewAll,
}: {
  dentists: Dentist[];
  onChange: (option: Dentist | null) => void;
  showSearchField?: boolean;
  disableViewAll: boolean;
}) => {
  const { selectedDentistId, search, setSearch } = useSelectedDentist();
  const {
    isOpen,
    setIsOpen,
    filteredDentistsByPractice,
    practices,
    handleOptionClick,
    getSelectedDentist,
    debouncedSearch,
    dentistsByPractice,
  } = useDentistSearch(dentists, onChange);

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/') {
        e.preventDefault();
        setIsOpen(true);
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);

    return () => {
      window.removeEventListener('keydown', handleGlobalKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <SelectButton
        selectedDentistId={selectedDentistId}
        disableViewAll={disableViewAll}
        setIsOpen={setIsOpen}
        getSelectedDentist={getSelectedDentist}
      />

      {isOpen && (
        <div className="origin-top-right absolute mt-2 py-1 w-[350px] rounded-md shadow-lg bg-white z-40 items-start flex flex-col">
          <div className="flex flex-col items-stretch w-full">
            {showSearchField && (
              <SearchInput
                search={search}
                setSearch={setSearch}
                setHighlightedIndex={setHighlightedIndex}
              />
            )}
            {dentists.length < 50 && !disableViewAll && (
              <button
                className={`block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200`}
                key={'All'}
                onClick={() => handleOptionClick(null)}
              >
                View All
              </button>
            )}
            {((dentists.length > 50 && debouncedSearch.length >= 3) || dentists.length <= 50) && (
              <DentistList
                practices={practices}
                filteredDentistsByPractice={filteredDentistsByPractice}
                dentistsByPractice={dentistsByPractice}
                search={search}
                highlightedIndex={highlightedIndex}
                handleOptionClick={handleOptionClick}
              />
            )}
            {dentists.length > 50 && debouncedSearch.length < 3 && (
              <div className="text-start px-4 py-2 text-sm">
                {dentists.length} Results - Enter at least 3 characters to search
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DentistSearchSelect;
