import { PayoutCluster } from '../../ServiceContext/invoices';

export function calculatePayoutAmounts(payoutInfo: PayoutCluster[]) {
  const allPayoutAmounts = payoutInfo.flatMap((cluster) =>
    cluster.summary.invoiceSummaries.map((summary) => summary.payoutAmount)
  );

  const flossyRecoupTotal = allPayoutAmounts
    .filter((amt) => amt < 0)
    .reduce((sum, amt) => sum + amt, 0);

  const payoutOwedToDentistTotal = allPayoutAmounts
    .filter((amt) => amt > 0)
    .reduce((sum, amt) => sum + amt, 0);

  const grossPayoutAmount = payoutOwedToDentistTotal;

  const netPayoutAmount = payoutInfo.reduce((sum, cluster) => sum + cluster.summary.total, 0);

  return {
    grossPayoutAmount,
    netPayoutAmount,
    flossyRecoupTotal,
  };
}
