import { Dentist } from '../../../ServiceContext/user';
import { usernameFromDentist } from '../../../shared/strings';
import { ArrowDown, CrowdIcon } from '../Icons';

type Props = {
  selectedDentistId: string | null;
  disableViewAll: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getSelectedDentist: () => Dentist | undefined;
};

const SelectButton: React.FC<Props> = ({
  selectedDentistId,
  disableViewAll,
  setIsOpen,
  getSelectedDentist,
}) => {
  return (
    <button
      type="button"
      className={`w-[350px] bg-secondary text-white inline-flex 
        items-center font-semibold px-4 py-3 text-sm border border-rule rounded-lg`}
      id="options-menu"
      aria-haspopup="true"
      aria-expanded="true"
      onClick={() => setIsOpen(true)}
    >
      <div className="flex flex-row gap-2 items-center grow">
        <div className="h-6 w-6 place-content-center">
          <CrowdIcon />
        </div>
        <div className="line-clamp-1 grow text-start">
          {selectedDentistId
            ? `Viewing: ${
                getSelectedDentist() !== undefined ? usernameFromDentist(getSelectedDentist()!) : ''
              }`
            : disableViewAll
            ? 'Search for a dentist'
            : 'Viewing: All Dentists'}
        </div>
      </div>
      <ArrowDown />
    </button>
  );
};

export default SelectButton;
