import React, { useEffect, useRef, useState } from 'react';

import { AuthProvider } from '../../../../Authentication/Authentication';
import SearchInput from '../../../../HomePage/components/DentistSearch/SearchInput';
import { groupDentistsByPractice } from '../../../../HomePage/components/DentistSearch/utils';
import { Practice } from '../../../../ServiceContext/user';
import PracticeList from './PracticeList';
import SelectButton from './SelectButton';
import { usePracticeSearch } from './usePracticeSearch';

interface PracticeSearchSelectProps {
  authProvider: AuthProvider;
  onChange: (option: Practice | null) => void;
  showSearchField?: boolean;
  disableViewAll: boolean;
  practices: Practice[];
  defaultSelectedPracticeId: string | null;
}

const PracticeSearchSelect: React.FC<PracticeSearchSelectProps> = ({
  authProvider,
  onChange,
  showSearchField = true,
  disableViewAll,
  practices,
  defaultSelectedPracticeId,
}) => {
  const [selectedPracticeId, setSelectedPracticeId] = useState<string | null>(
    defaultSelectedPracticeId
  );

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const {
    isOpen,
    setIsOpen,
    filteredPractices,
    search,
    setSearch,
    handleOptionClick,
    getSelectedPractice,
    debouncedSearch,
  } = usePracticeSearch(
    practices,
    groupDentistsByPractice(authProvider.managedDentists || []),
    onChange,
    selectedPracticeId,
    setSelectedPracticeId
  );

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/') {
        e.preventDefault();
        setIsOpen(true);
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);
    return () => window.removeEventListener('keydown', handleGlobalKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <SelectButton
        selectedPracticeId={selectedPracticeId}
        disableViewAll={disableViewAll}
        setIsOpen={setIsOpen}
        getSelectedPractice={getSelectedPractice}
      />

      {isOpen && (
        <div className="origin-top-right absolute mt-2 py-1 w-[350px] rounded-md shadow-lg bg-white z-40 items-start flex flex-col">
          <div className="flex flex-col items-stretch w-full">
            {showSearchField && (
              <SearchInput
                search={search}
                setSearch={setSearch}
                setHighlightedIndex={setHighlightedIndex}
              />
            )}
            {practices.length < 50 && !disableViewAll && (
              <button
                className="block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200"
                key={'All'}
                onClick={() => handleOptionClick(null)}
              >
                View All
              </button>
            )}
            {((practices.length > 50 && debouncedSearch.length >= 3) || practices.length <= 50) && (
              <PracticeList
                practices={filteredPractices}
                dentistsByPractice={groupDentistsByPractice(authProvider.managedDentists || [])}
                highlightedIndex={highlightedIndex}
                handleOptionClick={handleOptionClick}
              />
            )}
            {practices.length > 50 && debouncedSearch.length < 3 && (
              <div className="text-start px-4 py-2 text-sm">
                {practices.length} Results - Enter at least 3 characters to search
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PracticeSearchSelect;
