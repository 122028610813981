import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { ClickTrackingAction, GeneralWorkflow, runFTrack } from '../../../amplitude';
import { useSelectedDentist } from '../../../Dentists/SelectedDentistContext';
import { Dentist } from '../../../ServiceContext/user';
import { usernameFromDentist } from '../../../shared/strings';
import { extractPractices, filterDentists, groupDentistsByPractice } from './utils';

export const useDentistSearch = (
  dentists: Dentist[],
  onChange: (option: Dentist | null) => void
) => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedDentistId, setSelectedDentistId, search } = useSelectedDentist();
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const debouncedSetSearch = useMemo(() => {
    if (search.length < 3) return debounce(() => setDebouncedSearch(search), 0);
    return debounce(() => setDebouncedSearch(search), 1000);
  }, [search]);

  useEffect(() => {
    debouncedSetSearch();
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [search, debouncedSetSearch]);

  const dentistsByPractice = useMemo(() => groupDentistsByPractice(dentists), [dentists]);
  const practices = useMemo(() => extractPractices(dentists), [dentists]);

  const [filteredDentistsByPractice, setFilteredDentistsByPractice] = useState(dentistsByPractice);

  useEffect(() => {
    setTimeout(() => {
      if (dentists.length > 50 && (!debouncedSearch || debouncedSearch.length < 3)) {
        setFilteredDentistsByPractice(dentistsByPractice);
      } else {
        setFilteredDentistsByPractice(filterDentists(dentistsByPractice, search));
      }
    }, 0);
  }, [dentistsByPractice, debouncedSearch, search, dentists]);

  const handleOptionClick = (dentist: Dentist | null) => {
    setIsOpen(false);
    if (onChange) {
      onChange(dentist);
    }
    runFTrack({
      event: 'Select Dentist',
      workflow: GeneralWorkflow,
      action: ClickTrackingAction,
      context: 'dentistSearchSelect',
      componentId: 'dentistSearchSelectDropdown',
      extraProps: {
        dentistId: dentist?.id,
        dentistName: dentist !== null ? usernameFromDentist(dentist) : null,
      },
    });
    setSelectedDentistId(dentist?.id ?? null);
  };

  const getSelectedDentist = (): Dentist | undefined => {
    return dentists.find((dentist) => dentist.id === selectedDentistId);
  };

  return {
    isOpen,
    setIsOpen,
    filteredDentistsByPractice,
    practices,
    handleOptionClick,
    getSelectedDentist,
    debouncedSearch,
    dentistsByPractice,
  };
};
