import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useQuery } from 'react-query';

import {
  AppointmentWorkflow,
  ClickTrackingAction,
  runFTrack,
  SideEffectTrackingAction,
} from '../../amplitude';
import { getAppointments } from '../../API/appointments';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import DentistSearchSelect from '../../HomePage/components/DentistSearch/DentistSearchSelect';
import Login from '../../Login/Login';
import { Dentist } from '../../ServiceContext/user';
import ClearIcon from '../../shared/ClearIcon';
import Cookies from '../../shared/cookies/cookies';
import APIErrorAlert from '../../shared/errors/APIErrorAlert';
import PatientFilterInput from '../../shared/PatientFilterInput/PatientFilterInput';
import { pollingIntervalSeconds } from '../../shared/realTime/polling';
import { useURLQuery } from '../../shared/routing/routing';
import { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import NewPagination from '../../shared/Table/NewPagination';
import {
  activeFilterStyling,
  basicFilterStyling,
  inactiveFilterStyling,
} from '../../style/globalStyles';
import AppointmentsTable from './AppointmentsTable';

type Props = {
  authentication: AuthProvider;
};

const AppointmentsRollUp: React.FC<Props> = ({ authentication }) => {
  const pageSize = 10;

  const authUser = authentication.authUser;
  const { query, updateQuery } = useURLQuery();

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.appointmentSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const invoiceFilterFromQuery = query.get('hasInvoice');
  const defaultInvoiceFilter =
    invoiceFilterFromQuery !== null ? Boolean(invoiceFilterFromQuery) : undefined;

  const paymentFilterFromQuery = query.get('isInsurance');
  const defaultPaymentFilter =
    paymentFilterFromQuery !== null ? Boolean(paymentFilterFromQuery) : undefined;

  const treatmentPlanFilterFromQuery = query.get('hasTreatmentPlan');
  const defaultTreatmentPlanFilter =
    treatmentPlanFilterFromQuery !== null ? Boolean(treatmentPlanFilterFromQuery) : undefined;

  const getDentistOptions = useCallback(() => {
    if (!authentication.managedDentists) {
      return [];
    }
    return authentication.managedDentists;
  }, [authentication.managedDentists]);

  const categoryFromQuery = query.get('category');
  let defaultSelectedCategory: SearchSelectOption | null = null;
  if (categoryFromQuery) {
    let label = 'N/A';
    switch (categoryFromQuery) {
      case 'All Appointments':
        label = 'All Appointments';
        break;
      case 'Completed':
        label = 'Completed';
        break;
      case 'Scheduled':
        label = 'Scheduled';
        break;
      case 'Requested':
        label = 'Requested';
        break;
      case 'Unprocessed':
        label = 'Unprocessed';
        break;
      case 'Canceled':
        label = 'Canceled';
        break;
    }
    defaultSelectedCategory = {
      id: categoryFromQuery,
      label,
    };
  }

  const patientNameFromQuery = query.get('patientName');

  const currentPage = parseInt(query.get('page') || '1');

  const [appointmentCategory, setAppointmentCategory] = useState<SearchSelectOption>(
    defaultSelectedCategory || { id: 'All Appointments', label: 'All Appointments' }
  );

  const { selectedDentistId } = useSelectedDentist();

  const [patientName, setPatientName] = useState<string>(patientNameFromQuery || '');

  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);
  const [updater, setUpdater] = useState<number>(0);

  const [activeCategoryFilter, setActiveCategoryFilter] = useState(
    categoryFromQuery || 'All Appointments'
  );
  const [patientNameDisplay, setPatientNameDisplay] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateOrderByDirection, setDateOrderByDirection] = useState<'DESC' | 'ASC' | null>(null);

  const [isInsurance, setIsInsurance] = useState<boolean | undefined>(defaultPaymentFilter);
  const [hasInvoice, setHasInvoice] = useState<boolean | undefined>(defaultInvoiceFilter);
  const [hasTreatmentPlan, setHasTreatmentPlan] = useState<boolean | undefined>(
    defaultTreatmentPlanFilter
  );

  const timeMin = selectedDate
    ? DateTime.fromJSDate(selectedDate).startOf('day').toUTC().toString()
    : undefined;
  const timeMax = selectedDate
    ? DateTime.fromJSDate(selectedDate).endOf('day').toUTC().toISO()
    : undefined;
  const now = DateTime.local().toUTC().toString();

  const getAppointmentsQueryConfig = {
    queryKey: [
      'getAppointments',
      appointmentCategory?.id,
      selectedPage,
      pageSize,
      selectedDentistId,
      patientName,
      selectedDate,
      updater,
      dateOrderByDirection,
      isInsurance,
      hasInvoice,
      hasTreatmentPlan,
    ],
    queryFn: () => {
      const commonParams = {
        authProvider: authentication,
        page: selectedPage,
        pageSize: pageSize,
        dentistId: selectedDentistId ?? undefined,
        patientName: patientName.trim(),
        orderBy: 'start',
        orderByDirection: dateOrderByDirection || undefined,
        isInsurance,
        hasInvoice,
        hasTreatmentPlan,
      };

      switch (appointmentCategory?.id) {
        case 'Completed':
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : undefined,
            timeMax: timeMax ? timeMax : undefined,
            confirmStatus: 'confirmed',
            isProcessed: true,
            statuses: ['completed'],
          });
        case 'Scheduled':
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : now,
            timeMax: timeMax ? timeMax : undefined,
            confirmStatus: 'confirmed',
            isProcessed: false,
            statuses: ['open', 'requested'],
            isAcknowledged: true,
            orderByDirection: dateOrderByDirection || 'ASC',
          });
        case 'Unprocessed':
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : undefined,
            timeMax: timeMax ? timeMax : now,
            confirmStatus: 'confirmed',
            isProcessed: false,
            statuses: ['open', 'requested', 'completed'],
          });
        case 'Canceled':
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : undefined,
            timeMax: timeMax ? timeMax : undefined,
            isProcessed: false,
            statuses: ['cancelled'],
          });
        case 'Requested':
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : now,
            timeMax: timeMax ? timeMax : undefined,
            isAcknowledged: false,
          });
        default:
          return getAppointments({
            ...commonParams,
            timeMin: timeMin ? timeMin : undefined,
            timeMax: timeMax ? timeMax : undefined,
          });
      }
    },
    refetchInterval: pollingIntervalSeconds * 1000,
    enabled: true,
  };

  const { error: appointmentsError, data: appointmentsResponse } = useQuery(
    getAppointmentsQueryConfig
  );

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const onNewCategorySelected = useCallback(
    (category: string) => {
      setSelectedPage(1);
      runFTrack({
        event: 'Click Appointment Category',
        workflow: AppointmentWorkflow,
        action: ClickTrackingAction,
        context: 'needsAttention',
        componentId: 'appointmentCategoryFilter',
        extraProps: {
          category,
        },
      });
      setAppointmentCategory({ id: category, label: category });
      setDateOrderByDirection(null);
      setCookies({ page: '1', category: category });
    },
    [setCookies, setAppointmentCategory, setSelectedPage]
  );

  const onNewDentistSelected = useCallback(
    (selected: Dentist | null) => {
      setSelectedPage(1);
      if (selected) {
        setCookies({ page: '1', dentistId: selected.id });
      } else {
        setCookies({ page: '1', dentistId: null });
      }
    },
    [setCookies]
  );

  const onInvoiceFilter = useCallback(
    (selected: string | null) => {
      setSelectedPage(1);
      if (selected === 'Invoice') {
        setHasInvoice(true);
        setCookies({ page: '1', hasInvoice: 'true' });
      } else if (selected === 'Without Invoice') {
        setHasInvoice(false);
        setCookies({ page: '1', hasInvoice: 'false' });
      } else if (selected === null) {
        setHasInvoice(undefined);
        setCookies({ hasInvoice: undefined });
      }
    },
    [setCookies]
  );

  const onPaymentFilter = useCallback(
    (selected: string | null) => {
      setSelectedPage(1);
      if (selected === 'Insurance') {
        setIsInsurance(true);
        setCookies({ page: '1', isInsurance: 'true' });
      } else if (selected === 'Flossy') {
        setIsInsurance(false);
        setCookies({ page: '1', isInsurance: 'false' });
      } else if (selected === null) {
        setIsInsurance(undefined);
        setCookies({ isInsurance: undefined });
      }
    },
    [setCookies]
  );

  const onTreatmentPlanFilter = useCallback(
    (selected: string | null) => {
      setSelectedPage(1);
      if (selected === 'With TP') {
        setHasTreatmentPlan(true);
        setCookies({ page: '1', hasTreatmentPlan: 'true' });
      } else if (selected === 'Without TP') {
        setHasTreatmentPlan(false);
        setCookies({ page: '1', hasTreatmentPlan: 'false' });
      } else if (selected === null) {
        setHasTreatmentPlan(undefined);
        setCookies({ hasTreatmentPlan: undefined });
      }
    },
    [setCookies]
  );

  const onPatientInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setPatientName(e.target.value);
      setSelectedPage(1);
      runFTrack({
        event: 'Search By Patient Name',
        workflow: AppointmentWorkflow,
        action: SideEffectTrackingAction,
        context: 'appointmentsRollUp',
        componentId: 'searchByPatientNameInput',
      });
      setCookies({ page: '1', patientName: e.target.value });
    },
    [setCookies]
  );

  const onPatientInputClear = useCallback(() => {
    setPatientName('');
    setSelectedPage(1);
    setCookies({ page: '1', patientName: null });
  }, [setCookies]);

  const onPatientInputEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setPatientName(e.currentTarget.value);
        setSelectedPage(1);
        setCookies({ page: '1', patientName: e.currentTarget.value });
      }
    },
    [setCookies]
  );

  const onPatientInputChange = useCallback(
    (newValue: string | null) => {
      if (newValue === null) {
        setPatientNameDisplay('');
        setCookies({ page: '1', patientName: null });
      } else {
        setPatientNameDisplay(newValue);
      }
    },
    [setCookies]
  );

  const onClearDateClick = useCallback(() => {
    setSelectedDate(null);
    setSelectedPage(1);
    setCookies({ page: '1', timeMin: null, timeMax: null });
  }, [setCookies]);

  if (!authUser || !authentication.isLoggedIn) {
    return <Login authentication={authentication} />;
  }

  if (!appointmentsResponse) {
    return null;
  }

  return (
    <div id="appointments-page" className="flex flex-col justify-start items-start text-secondary">
      <div id="header" className="flex flex-row justify-between w-full mb-4">
        <span className="font-semibold text-2xl">Appointments</span>
        <DentistSearchSelect
          onChange={onNewDentistSelected}
          dentists={getDentistOptions()}
          showSearchField={true}
          disableViewAll={false}
        />
      </div>
      <APIErrorAlert errorResponse={appointmentsError as ErrorResponse | null} />

      <div className="appointments-search-and-filter flex flex-row justify-between bg-white w-full py-4 px-4 rounded-t-md border-b border-rule">
        <div className="appointment-categories flex flex-row gap-2 mr-8">
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'All Appointments'
                ? activeFilterStyling
                : inactiveFilterStyling
            } `}
            onClick={() => {
              setActiveCategoryFilter('All Appointments');
              onNewCategorySelected('All Appointments');
            }}
          >
            <div className="line-clamp-1">All Appointments</div>
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Requested' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Requested');
              onNewCategorySelected('Requested');
            }}
          >
            Requested
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Scheduled' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Scheduled');
              onNewCategorySelected('Scheduled');
            }}
          >
            Scheduled
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Unprocessed' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Unprocessed');
              onNewCategorySelected('Unprocessed');
            }}
          >
            Unprocessed
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Completed' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Completed');
              onNewCategorySelected('Completed');
            }}
          >
            Completed
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Canceled' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Canceled');
              onNewCategorySelected('Canceled');
            }}
          >
            Canceled
          </button>
        </div>
        <div id={'search-by-patient-name-and-date'} className="text-sm flex flex-row gap-2">
          <PatientFilterInput
            onKeyDown={onPatientInputEnter}
            customStyling="border-[1px] rounded-md border-rule"
            onChange={onPatientInputChange}
            patientNameInputValue={patientNameDisplay}
            placeholder="Search by patient name"
            isSearchBox
            onBlur={onPatientInputBlur}
            onClear={onPatientInputClear}
            allowClear={true}
          />
          <div className="flex flex-row relative">
            <DatePicker
              id="appointment-date-picker"
              className="appointment-time-picker border-[1px] border-rule rounded-md py-2.5 pl-10 pr-12 focus:outline-0 w-full"
              autoComplete="off"
              placeholderText="Select date"
              selected={selectedDate}
              onChange={(dateValue) => {
                if (typeof dateValue === 'object') {
                  const date = dateValue as Date;
                  runFTrack({
                    event: 'Search By Date',
                    workflow: AppointmentWorkflow,
                    action: SideEffectTrackingAction,
                    context: 'appointmentsRollUp',
                    componentId: 'searchByDateInput',
                  });
                  setSelectedDate(date);
                }
              }}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content"
            />
            <div className="absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon noDisplay={!selectedDate} onClick={onClearDateClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="table-and-pagination w-full overflow-x-hidden overflow-y-visible h-1/2 relative">
        <AppointmentsTable
          appointmentData={appointmentsResponse.appointments}
          loadingErrorMessage=""
          setDateOrderByDirection={setDateOrderByDirection}
          setUpdater={setUpdater}
          onInvoiceFilterChange={onInvoiceFilter}
          initialInvoiceFilter={invoiceFilterFromQuery || undefined}
          onPaymentFilterChange={onPaymentFilter}
          initialPaymentFilter={paymentFilterFromQuery || undefined}
          onTreatmentPlanFilterChange={onTreatmentPlanFilter}
          initialTreatmentPlanFilter={treatmentPlanFilterFromQuery || undefined}
        />
        {appointmentsResponse && (
          <NewPagination
            currentPage={selectedPage}
            pageSize={pageSize}
            totalItemCount={appointmentsResponse.totalCount}
            onPageChange={onNewPageSelected}
            tracking={{
              workflow: AppointmentWorkflow,
              context: 'appointmentsRollup',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AppointmentsRollUp;
