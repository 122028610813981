import { DateTime } from 'luxon';

import { AuthProvider } from '../Authentication/Authentication';
import {
  authenticatedGet,
  authenticatedPost,
  performUnauthenticatedRequest,
} from '../ServiceContext/service';
import { apiUrl, Timestamp } from '../ServiceContext/shared';
import { Practice } from '../ServiceContext/user';
import { ErrorResponse, handleAPIResponse } from './response';

export type BookingLedger = {
  totalBalance: number;
  balanceDue: number;
  balanceDueDate: string;
  appointmentIds: string[];

  totalCharges: {
    count: number;
    sum: number;
    appointmentIds: string[];
  };
  totalCredits: {
    count: number;
    sum: number;
  };
  monthlyBreakdown: {
    //'2024-05'
    [monthYear: string]: LedgerMonth;
  };
  payments: BookingLedgerPayment[];
};

export type LedgerMonth = {
  period: string; // of form YYYY-MM
  balance: number;
  charges: {
    count: number;
    sum: number;
    breakdown: ChargesBreakdown;
    items: BookingCharge[];
  };
  credits: {
    count: number;
    sum: number;
    items: BookingChargeCredit[];
  };
  bookings: Booking[];
  breakdown: BookingsBreakdown;
};

export enum PracticePaymentStatus {
  // The payment is still being processed by Stripe.
  PROCESSING = 'processing',

  // The payment has been marked as cancelled by Stripe.
  CANCELLED = 'cancelled',

  // The payment has failed to process for any reason.
  FAILED = 'failed',

  // The payment has succeeded and the equivalent amount of money is removed from the practice's balance.
  SUCCEEDED = 'succeeded',
}

export enum PracticePaymentBalanceType {
  // The payment was made for the balance due (what the practice was obligated to pay Flossy as of the payment date).
  BALANCE_DUE = 'balance-due',

  // The payment was made for the total balance (the balance due as of the payment date, plus any charges thereafter).
  TOTAL_BALANCE = 'total-balance',
}

export type BookingLedgerPayment = {
  id: string;
  practiceId: string;
  amount: number;
  status: PracticePaymentStatus;
  failureMessage?: string | null;
  balanceType: PracticePaymentBalanceType;
  paymentDate: string;
};

export type BookingsBreakdown = {
  totalCount: number;
  insuranceBookings: number;
  nonInsuranceBookings: number;
  paidWithInsurance: number;
  paidWithoutInsurance: number;
  pendingPayment: number;
};

export type ChargesBreakdown = {
  disputed: number;
  disputeRejected: number;
  disputeRefunded: number;
  paid: number;
};

export type Booking = {
  appointmentId: string;
  bookedAt: Timestamp;
  appointmentStartTime: Timestamp;
  appointmentTimeZone: string;
  isInsuranceAppointment: boolean;
  dentist: {
    name: string;
  };
  patient: {
    id: string;
    name: string;
  };
  charge: {
    id: string;
    amount: number;
    state: BookingChargeState;
    history: BookingChargeHistory[];
    appliedAt: Timestamp;
  } | null;
};

export enum BookingChargeState {
  // The charge is pending and has not been processed yet.
  PENDING = 'pending',

  // The charge was cancelled before the post-booking waiting/grace period had passed.
  CANCELLED = 'cancelled',

  // The charge was voided for any reason, or cancelled after the post-booking waiting/grace period had passed.
  VOID = 'void',

  // The charge has been paid by the practice and no longer affects balances.
  SETTLED = 'settled',

  // This state is Deprecated.
  DISPUTED = 'disputed',

  // The charge had once been settled, but has been refunded for any reason.
  REFUNDED = 'refunded',
}

export enum BookingChargeCreditState {
  // The credit is pending and has not been applied to the practice ledger yet.
  PENDING = 'pending',

  // The credit was cancelled in some scenario, for example -- when an associated appointment was cancelled
  // prior to the appointment grace period passing.
  CANCELLED = 'cancelled',

  // The credit was voided for any reason.
  VOID = 'void',

  // The credit has been applied to the practice ledger.
  APPLIED = 'applied',

  // The credit has participated in a payment, and is no longer available to be applied to the practice ledger.
  CONSUMED = 'consumed',
}

export type BookingChargeHistory = {
  id: string;
  bookingChargeId: string;
  previousState: BookingChargeState | null;
  newState: BookingChargeState;
  reason: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type BookingCharge = {
  id: string;
  amount: number;
  state: BookingChargeState;
  history: BookingChargeHistory[];
  appliedAt: Timestamp;
};

export type BookingChargeCredit = {
  id: string;
  creditType: 'patient_referral' | 'grant';
  settlesChargeId: string | null;
  status: BookingChargeCreditState;
  appliesAt: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  practiceId: string;
};

export function datePaymentAppliesTo(payment: BookingLedgerPayment): string | null {
  const paymentDate = DateTime.fromISO(payment.paymentDate);

  if (payment.balanceType === PracticePaymentBalanceType.BALANCE_DUE) {
    return paymentDate.minus({ months: 1 }).endOf('month').toISODate();
  } else if (payment.balanceType === PracticePaymentBalanceType.TOTAL_BALANCE) {
    return paymentDate.toISODate();
  }

  return null;
}

type APIBookingCharge = {
  id: string;
  practiceId: string;
  appointmentId: string;
  amount: number;
  currentState: BookingChargeState;
  createdAt: Date;
  history: BookingChargeHistory[];
  appliesAt: Timestamp;
};

export async function refundCharge({
  authProvider,
  practiceId,
  bookingChargeId,
  reason,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  bookingChargeId: string;
  reason: string;
}): Promise<BookingCharge> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  const res = await handleAPIResponse(
    authenticatedPost<APIBookingCharge | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/${practiceId}/booking-charges/${bookingChargeId}/refunds`),
      {
        reason,
      }
    )
  );
  return {
    id: res.id,
    amount: res.amount,
    state: res.currentState,
    history: res.history,
    appliedAt: res.appliesAt,
  };
}

export async function getBookingLedger({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string | null;
}): Promise<BookingLedger | null> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (practiceId) {
    return handleAPIResponse(
      authenticatedGet<BookingLedger>(
        authProvider,
        apiUrl(`/practices/${practiceId}/booking-ledger`)
      )
    );
  }
  if (authUser.user.isFlossyAdmin) {
    return handleAPIResponse(
      authenticatedGet<BookingLedger>(
        authProvider,
        apiUrl(`/managers/${authUser.user.id}/booking-ledger`)
      )
    );
  }
  return null;
}

export type PaymentHistoryRecord = {
  id: string;
  createdAt: Timestamp;

  //Provides Date Paid - only relevant when a payment succeeds
  startedProcessingAt: Timestamp | null;
  status: 'pending' | 'processing' | 'cancelled' | 'failed' | 'succeeded';
  amount: number;

  //Provides Payment Period - Historical payments will not have this details obj
  details:
    | {
        data: {
          type: 'single-month';
          year: number;
          month: number;
        };
      }
    | {
        data: {
          type: 'as-of-date';
          asOfDate: string; // MM/YYYY
        };
      }
    | null;

  //Only relevant to payments that do not have the details obj
  paymentBalanceDate: Timestamp | null;
};

export async function getPaymentHistory({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string | null;
}): Promise<PaymentHistoryRecord[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (!practiceId) {
    return handleAPIResponse(
      authenticatedGet<PaymentHistoryRecord[]>(
        authProvider,
        apiUrl(`/managers/${authUser.user.id}/bookings-and-credits-payments`)
      )
    );
  }
  return handleAPIResponse(
    authenticatedGet<PaymentHistoryRecord[]>(
      authProvider,
      apiUrl(`/practices/${practiceId}/bookings-and-credits-payments`)
    )
  );
}

type APIPaymentLink = {
  id: string;
  email: string;
  isValid: boolean;
  createdAt: Date;
  version: number;
  practice: Practice;
};

export async function createPaymentLinks({
  authProvider,
  practiceId,
  emailAddresses,
  paymentDetails,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  emailAddresses: Array<string>;
  paymentDetails: {
    type: 'single-month';
    year: number;
    month: number;
  };
}): Promise<APIPaymentLink[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost<APIPaymentLink[]>(
      authProvider,
      apiUrl(`/practices/${practiceId}/bookings-and-credits-payment-links`),
      {
        emails: emailAddresses,
        balance: paymentDetails,
      }
    )
  );
}

export type PaymentLinkContent = {
  email: string;
  practiceName: string;
  balanceDue: number;
  balanceDueDate: Timestamp;
  paymentDate: Timestamp | null;
  details: {
    data: {
      type: 'single-month';
      year: number;
      month: number;
    };
  } | null;
  isValid: boolean;
};

export async function getPaymentLink({ paymentLinkId }: { paymentLinkId: string }) {
  return handleAPIResponse(
    performUnauthenticatedRequest<PaymentLinkContent | ErrorResponse>({
      method: 'GET',
      url: apiUrl(`/practices/bookings-and-credits-payment-links/${paymentLinkId}/details`),
    })
  );
}
