import React from 'react';

import { Dentist, Practice } from '../../../ServiceContext/user';
import { usernameFromDentist } from '../../../shared/strings';
import { CrowdIcon } from '../Icons';

interface DentistListProps {
  practices: Practice[];
  filteredDentistsByPractice: Record<string, Dentist[]>;
  dentistsByPractice: Record<string, Dentist[]>;
  search: string;
  highlightedIndex: number;
  handleOptionClick: (dentist: Dentist | null) => void;
}

const DentistList: React.FC<DentistListProps> = ({
  practices,
  filteredDentistsByPractice,
  dentistsByPractice,
  search,
  highlightedIndex,
  handleOptionClick,
}) => {
  return (
    <div className="items-start justify-start flex flex-col">
      {practices.map((practice) => {
        const noResults =
          !filteredDentistsByPractice[practice.id] ||
          filteredDentistsByPractice[practice.id].length === 0;

        const searchMatchesPractice = practice.name
          ? practice.name.toLowerCase().includes(search.toLowerCase())
          : false;

        const practiceDentists = searchMatchesPractice
          ? dentistsByPractice[practice.id]
          : filteredDentistsByPractice[practice.id];

        if (search && !practice.name && noResults) {
          return null;
        }
        if (
          noResults &&
          practice.name &&
          !practice.name.toLowerCase().includes(search.toLowerCase())
        ) {
          return null;
        }

        return (
          <div key={practice.id}>
            <div className="flex flex-row items-center gap-2 mt-3 mx-4">
              <div className="h-4 w-4 place-content-center">
                <CrowdIcon color="black" />
              </div>
              <div className="text-start font-semibold text-sm">
                {practice.name ?? 'No Practice Name'}
              </div>
            </div>

            {practiceDentists &&
              practiceDentists.map((dentist, index) => (
                <button
                  className={`block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200 ${
                    index === highlightedIndex ? 'bg-base-100' : ''
                  }`}
                  key={dentist.id}
                  onClick={() => handleOptionClick(dentist)}
                >
                  {usernameFromDentist(dentist)}
                </button>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default DentistList;
