import React from 'react';

import { ArrowDown, CrowdIcon } from '../../../../HomePage/components/Icons';
import { Practice } from '../../../../ServiceContext/user';

interface SelectButtonProps {
  selectedPracticeId: string | null;
  disableViewAll: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getSelectedPractice: () => Practice | undefined;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  selectedPracticeId,
  disableViewAll,
  setIsOpen,
  getSelectedPractice,
}) => {
  return (
    <button
      type="button"
      className="w-[350px] bg-secondary text-white inline-flex items-center font-semibold px-4 py-3 text-sm border border-rule rounded-lg"
      id="options-menu"
      aria-haspopup="true"
      aria-expanded="true"
      onClick={() => setIsOpen(true)}
    >
      <div className="flex flex-row gap-2 items-center grow">
        <div className="h-6 w-6 place-content-center">
          <CrowdIcon />
        </div>
        <div className="line-clamp-1 grow text-start">
          {selectedPracticeId
            ? `Viewing: ${getSelectedPractice()?.name || ''}`
            : disableViewAll
            ? 'Search for a practice'
            : 'Viewing: All Practices'}
        </div>
      </div>
      <ArrowDown />
    </button>
  );
};

export default SelectButton;
